import React, { useState, useEffect } from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { StaticQuery, graphql } from "gatsby"

import Gate from "./_sections/_gate"
import OpeningSection from "./_sections/_opening"
import FocusSection from "./_sections/_focus"

import ImageDetails from "./_components/_imageDetails"

function GalleryPage({ location }) {
  const [password, setPassword] = useState("")
  const [nextPossible, setNextPossible] = useState(false)
  const [previousPossible, setPreviousPossible] = useState(false)
  const [passwordWrong, setPasswordWrong] = useState("")
  const [passwordChecked, setPasswordChecked] = useState(true)
  const [activeSection, setActiveSection] = useState("all")
  const [modalOpen, setModalOpen] = useState(false)
  const [activeGallery, setActiveGallery] = useState([])
  const [activeImage, setActiveImage] = useState(undefined)

  useEffect(() => {
    if (location.search && location.search.indexOf("?filter=") !== -1) {
      setActiveSection(location.search.replace("?filter=", ""))
    }
  }, [])

  useEffect(() => {
    setPreviousPossible(false)
    setNextPossible(false)
    activeGallery.forEach((image, index) => {
      if (image.id === activeImage.id) {
        if (index > 0) {
          setPreviousPossible(true)
        }
        if (index < activeGallery.length - 1) {
          setNextPossible(true)
        }
      }
    })
  }, [activeGallery, activeImage])

  const next = function() {
    activeGallery.forEach((image, index) => {
      if (image.id === activeImage.id) {
        setActiveImage(activeGallery[index + 1])
      }
    })
  }

  const previous = function() {
    activeGallery.forEach((image, index) => {
      if (image.id === activeImage.id) {
        setActiveImage(activeGallery[index - 1])
      }
    })
  }

  const openImage = function(images, image) {
    setActiveGallery(images)
    setActiveImage(image)
    setModalOpen(true)
  }

  const checkPassword = function(password) {
    if (password === "Cladding4100") {
      setPasswordChecked(true)
    } else {
      setPasswordWrong(true)
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          page: datoCmsInspiration {
            title
            subtitle
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            seoKeywords
          }
          galleryImages: allDatoCmsGalleryItem {
            nodes {
              image {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyDatoCmsFluid
                }
              }
              category
              title
              subtitle
              details
              dimensions
              panoramaLink {
                link
              }
            }
          }
        }
      `}
      render={data => {
        let sortedGallery = {
          Education: [],
          Commercial: [],
          Healthcare: [],
          Refreshment: [],
        }
        if (data.galleryImages.nodes) {
          data.galleryImages.nodes.forEach((imageItem, index) => {
            if (imageItem.category && sortedGallery[imageItem.category]) {
              sortedGallery[imageItem.category].push({
                ...imageItem,
                fluid: imageItem.image.fluid,
                alt: imageItem.image.alt,
                aspectRatio:
                  imageItem.image.fluid && imageItem.image.fluid.aspectRatio,
                id: index,
              })
            }
          })
        }
        return (
          <>
            <HelmetDatoCms seo={data.page.seoMetaTags}>
              <meta name="keywords" content={data.page.seoKeywords} />
            </HelmetDatoCms>
            {!passwordChecked && (
              <Gate
                password={password}
                setPassword={setPassword}
                passwordWrong={passwordWrong}
                checkPassword={checkPassword}
              />
            )}
            {passwordChecked && (
              <>
                <OpeningSection
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                  title={data.page.title}
                  subtitle={data.page.subtitle}
                />
                {(activeSection === "all" ||
                  activeSection === "commercial") && (
                  <FocusSection
                    title={`Commercial`}
                    openImage={openImage}
                    images={sortedGallery["Commercial"]}
                  />
                )}
                {(activeSection === "all" || activeSection === "education") && (
                  <FocusSection
                    title={`Education`}
                    openImage={openImage}
                    images={sortedGallery["Education"]}
                  />
                )}
                {(activeSection === "all" ||
                  activeSection === "healthcare") && (
                  <FocusSection
                    title={`Healthcare`}
                    openImage={openImage}
                    images={sortedGallery["Healthcare"]}
                  />
                )}
                {(activeSection === "all" ||
                  activeSection === "refreshment") && (
                  <FocusSection
                    title={`Refreshment`}
                    openImage={openImage}
                    images={sortedGallery["Refreshment"]}
                  />
                )}
                <ImageDetails
                  showDialog={modalOpen}
                  setShowDialog={setModalOpen}
                  activeGallery={activeGallery}
                  activeImage={activeImage}
                  data={data}
                  next={next}
                  nextPossible={nextPossible}
                  previous={previous}
                  previousPossible={previousPossible}
                />
              </>
            )}
          </>
        )
      }}
    />
  )
}

export default GalleryPage
